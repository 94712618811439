export default {
    namespaced: true,
    state: {
        count: 0,
        savedValue:[]
    },
    getters: {
        count: state => state.count,
        savedValue: state => state.savedValue
    },
    mutations: {
        increment (state) {
            state.count++
        },
        decrement (state) {
            state.count--
        },
        saveValue (state, value) {
            state.savedValue.push(value)
        },
        clearValue (state) {
            state.savedValue = []
            state.count = 0
        }
    },
    actions: {
        async increment ({ commit }) {
            commit('increment')
        },
        async decrement ({ commit }) {
            commit('decrement')
        },
        async saveValue ({ commit }, value) {
            commit('saveValue', value)
        },
        async clearValue ({ commit }) {
            commit('clearValue')
        }
    }
}