import { createStore } from 'vuex'
import counterApp from './counterApp'
import advanceCounterApp from './advanceCounterApp'

export default createStore({
  modules: {
    counterApp,
    advanceCounterApp
  }
})
