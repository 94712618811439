export default {
    namespaced: true,
    state: {
        count: 0
    },
    getters: {
        count: state => state.count
    },
    mutations: {
        increment (state) {
            state.count++
        }
    },
    actions: {
        async increment ({ commit }) {
            commit('increment')
        }
    }
}