import { createRouter, createWebHistory } from 'vue-router'
import CounterView from '@/views/CounterView.vue'
import HomeView from '@/views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/counter-app',
    name: 'counter-app',
    component: CounterView
  },
  {
    path: '/advance-counter-app',
    name: 'advance-counter-app',
    component: () => import('@/views/AdvanceCounterView.vue')
  },
  {
    path: '/text-editor-app',
    name: 'text-editor-app',
    component: () => import('@/views/TextEditorView.vue')
  },
  {
    path: '/sidebar',
    name: 'sidebar',
    component: () => import('@/views/SidebarView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
