<template>
    <div class="d-flex flex-column w-100 align-items-center justify-content-space-between">
         <the-navbar/>
        <div class="d-flex flex-grow-1 flex-column text-start align-items-left justify-content-center">
            <router-link to="/counter-app">Counter App</router-link>
            <router-link to="/advance-counter-app">Advance Counter App</router-link>
            <router-link to="/text-editior-app">Text Editior</router-link>
            <router-link to="/sidebar">Sidebar</router-link>
        </div>
    </div>
</template>

<script>

import TheNavbar from "@/components/TheNavbar.vue";

export default {
    components:{
        TheNavbar,
    }
}
</script>


<style scoped>
.router-link {
    margin: 10px;
    text-decoration: none;
}

</style>


