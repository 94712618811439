<template>
  <div class="container-fluid nav-shadow">
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
      <div class="container">
        <a class="navbar-brand logo" href="#"
          >JS<span class="logo">Tut</span></a
        >
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav mx-auto">
            <a class="nav-link active" aria-current="page" href="#">Intro</a>
            <a class="nav-link" href="#">Projects</a>
            <a class="nav-link" href="#">About</a>
          </div>
           <div class="get-start">
        <a class="get-start-btn">Get Started</a>
      </div>
        </div>
       
      </div>
      
    </nav>
  </div>
</template>


<style scoped>
.nav-shadow {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.logo {
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;
}
.get-start {
  background: linear-gradient(97.42deg, #128dff -4.95%, #1444ef 109.04%);
  border-radius: 4px;
    border: none;
    padding: 10px 35px;
    cursor: pointer;
}

.get-start a {
    text-decoration: none;
    color: #eee;
    background: transparent;
}

</style>