<template>
  <div class="counter">
    <h1>Counter App</h1>
    <h2>{{ count }}</h2>
    <button class="add-btn" @click="increment">Add One</button>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';

export default {
  computed:{
    ...mapGetters('counterApp',{
      count: 'count',
    }),
  },
  methods: {
    ...mapActions('counterApp',[
        'increment',
        ])
  },
};
</script>

<style scoped>
.counter {
  height: 268px;
  width: 468px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  justify-content: space-around;
  margin: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.add-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 13px 35px;
  color: #eee;
  background: linear-gradient(97.42deg, #128dff -4.95%, #1444ef 109.04%);
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.add-btn:hover {
  background: linear-gradient(50.42deg, #128dff -0.95%, #1444ef 109.04%);
}
</style>


