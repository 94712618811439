<template>
    <counter-app></counter-app>
</template>

<script>

import CounterApp from '@/components/CounterApp.vue';

export default {
   components: {
      CounterApp,
   }, 
}
</script>